'use es6';

import enviro from 'enviro';
import { log, warn, error, createException } from './Logger';
const defer = func => {
  // Run function only when call stack is clear.
  // This makes the call async, meaning that:
  //  - it lets more important logic run first.
  //  - if an exception is thrown, it doesn't break the execution flow.
  setTimeout(func, 0);
};
const shortenTimestamp = timestamp => {
  // Wootric expects a timestamp in seconds, not ms.
  return Math.floor(timestamp / 1000);
};
const readItemFromLocalStorage = key => {
  let value = false;
  try {
    value = localStorage.getItem(key);
  } catch (e) {
    // ¯\_(ツ)_/¯
  }
  return value;
};
const isHubSpotter = userEmail => {
  const email = typeof userEmail === 'string' ? userEmail : '';
  const acceptanceTestsEmailSuffix = enviro.isQa() ? 'hsemailsendingtestqa.com' : 'hsemailsendingtest.com';
  return email.toLowerCase().indexOf('@hubspot.com') >= 0 || email.toLowerCase().endsWith(acceptanceTestsEmailSuffix);
};
const fetchScript = scriptSrc => {
  const page = document.head || document.body;
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.async = true;
  script.src = scriptSrc;
  page.appendChild(script);
  return new Promise((resolve, reject) => {
    script.onload = () => {
      resolve();
    };
    script.onerror = () => {
      reject(new Error(`could not load ${scriptSrc}`));
    };
  });
};
const isForceOpen = () => {
  return String(readItemFromLocalStorage('wootric-nps-open')) === 'true';
};
const getBeaconUrl = () => {
  if (!enviro.deployed()) {
    return '//local.hsappstatic.net/wootric-nps/static-1.4458/vendor/beacon.js';
  }
  const domainSuffix = enviro.getShort() === 'qa' ? '-qa' : '';
  return `//static.hsappstatic.net/wootric-nps/ex/beacon${domainSuffix}.js`;
};
export { log, warn, error, createException, shortenTimestamp, defer, readItemFromLocalStorage, isHubSpotter, fetchScript, isForceOpen, getBeaconUrl };